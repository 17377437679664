<template>
  <div class="page-branding">
    <div class="banner" style="background: gray;">
      <video autoplay muted loop playsinline v-if="catVid">
        <source :src="catVid" />Your browser does not support the video tag.
      </video>
    </div>

    <div class="content works-contianer">
      <div @click="goBack" class="back-btn mb-3">
        <span class="mr-2 d-flex align-items-center">
          <i class="arrow left"></i>
        </span>
        <!-- <img src="@/assets/img/icon/caret-square-left-regular.svg" alt /> -->
        <span>{{ $t("global.back") }}</span>
      </div>

      <h2 v-if="getCat">{{ getCat.name }}</h2>
      <b-row v-if="works">
        <b-col cols="12" md="4" v-for="(work, i) in works.data" :key="work.id + $i18n.locale">
          <WorkCard :work="work" @click.native="(showModal = true), (selectedWork = i)" />
        </b-col>
      </b-row>

      <div v-if="works && !works.data.length">
        <h2>0 Work Found</h2>
      </div>

      <div class="text-center mt-5 d-flex justify-content-center" @click="loadMore()"
        v-if="works && works.next_page_url">
        <div class="load-more">{{ $t("global.loadMore") }}</div>
      </div>
    </div>

    <transition name="modal">
      <WorkSwiperModal v-if="showModal" :works="works.data" :selectedWork="selectedWork" />
    </transition>
  </div>
</template>

<script>
import { bus } from '@/main'
import axios from 'axios'
export default {
  name: 'Branding',
  metaInfo() {
    return {
      title: () => {
        const title = this.$route.params.cat
        return this.makeTitle(title)
      }
    }
  },
  data() {
    return {
      param: {
        params: {
          lang: null
        }
      },
      showModal: false,
      selectedWork: null
    }
  },
  created() {
    this.getWorks()
  },
  computed: {
    allCats() {
      return this.$t('categories').data
    },
    getCat() {
      return this.allCats.find(el => el.slug === this.$route.params.cat)
    },
    works() {
      return this.$t('works')?.mainWorks?.find(el => el.slug === this.$route.params.cat)
    },
    catVid() {
      let res = ''
      try {
        res = require(`@/assets/video/${this.getCat.id}.mp4`)
      } catch (error) {
        console.log(error)
      }
      return res
    }
  },
  methods: {
    makeTitle(slug) {
      var words = slug.split('-');
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
      return words.join(' ');
    },
    async loadMore() {
      // console.log(this.$t('works.data')[1].next_page_url)
      const url = this.works.next_page_url
      await axios.get(url, this.param)
        .then(res => {
          res.data[0].forEach(newWork => {
            this.works.data.push(newWork)
            this.works.next_page_url = res?.data[1]?.next_page_url
          })
        })
    },
    getWorks() {
      this.param.params.lang = this.$i18n.locale
      this.param.params.cat = this.$route.params.cat
      // console.log(!this.$t('works.data')[this.getCat.toLowerCase()][0])
      // console.log(this.$t('works').mainWorks.some(el => el.slug === this.getCat.toLowerCase()))
      if (
        !this.$t('works').mainWorks.some(el => el.slug === this.$route.params.cat)
      ) {
        console.log('fetch post')
        this.$store.dispatch('fetchWorks', this.param)
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  components: {
    WorkCard: () => import('./partials/WorkCard.vue'),
    WorkSwiperModal: () => import('./partials/SwiperModal.vue')

  },
  mounted() {
    bus.$on('updateWorkLocate', this.getWorks)
    bus.$on('closeModal', () => { this.showModal = false })
    // console.log(this.works)
  }
}
</script>

<style lang='scss'>
.page-branding {
  padding: 150px 0;

  .works-contianer {
    [class*="col-"] {
      padding: 5px;
      padding-bottom: 0;
      margin-bottom: 5px;
      overflow: hidden;
    }
  }

  .banner {
    height: 250px;
    margin-bottom: 80px;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: 768px) {
    .banner {
      height: 600px;
    }
  }

}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
</style>
